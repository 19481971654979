import React, { Component } from "react";
import "normalize.css";
import Carousel from "nuka-carousel";
import styled from "styled-components";
import { css } from "styled-components";
import Typography from "typography";

const typography = new Typography({
  baseFontSize: "14px",
  baseLineHeight: 1.666,
  headerFontFamily: [
    "Avenir Next",
    "Helvetica Neue",
    "Segoe UI",
    "Helvetica",
    "Arial",
    "sans-serif",
  ],
  bodyFontFamily: [
    "Avenir Next",
    "Helvetica Neue",
    "Segoe UI",
    "Helvetica",
    "Arial",
    "sans-serif",
  ],
  // See below for the full list of options.
});

typography.injectStyles();

const Container = styled.div`
  width: 75%;
  max-width: 1400px;
  margin: 0 auto;
  padding-top: 4rem;
  a {
    text-decoration: none;
    color: lightslategray;
    &:hover {
      color: black;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 95%;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 1.666rem;
`;

const Name = styled.h1`
  min-width: 50%;
  font-size: 1.2rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  @media only screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Contact = styled.div`
  min-width: 50%;
  text-align: right;
  align-items: center;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  a {
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
  @media only screen and (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const ImagesContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  img {
    width: 100%;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const Arrow = styled.button`
  background: none;
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
  text-indent: -9999px;
  border: 0;
  background: none;
  background-position: center center;
  padding: 8px;
  background-repeat: no-repeat;
  background-size: 8px;
  align-self: center;
  cursor: pointer;
  opacity: 0.6;
  outline: 0;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjI2cHgiIGhlaWdodD0iNTBweCIgdmlld0JveD0iMCAwIDI2IDUwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy4zLjIgKDEyMDQzKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5wcmV2PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+CiAgICAgICAgPGcgaWQ9InByZXYiIHNrZXRjaDp0eXBlPSJNU0xheWVyR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzLjM5MzE5MywgMjUuMDAwMDAwKSBzY2FsZSgtMSwgMSkgdHJhbnNsYXRlKC0xMy4zOTMxOTMsIC0yNS4wMDAwMDApIHRyYW5zbGF0ZSgwLjg5MzE5MywgMC4wMDAwMDApIiBmaWxsPSIjNTY1QTVDIj4KICAgICAgICAgICAgPHBhdGggZD0iTTAsNDkuMTIzNzMzMSBMMCw0NS4zNjc0MzQ1IEwyMC4xMzE4NDU5LDI0LjcyMzA2MTIgTDAsNC4yMzEzODMxNCBMMCwwLjQ3NTA4NDQ1OSBMMjUsMjQuNzIzMDYxMiBMMCw0OS4xMjM3MzMxIEwwLDQ5LjEyMzczMzEgWiIgaWQ9InJpZ2h0IiBza2V0Y2g6dHlwZT0iTVNTaGFwZUdyb3VwIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
  &:hover {
    opacity: 1;
  }
  ${props =>
    props.nextArrow &&
    css`
      margin-left: 2rem;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjI2cHgiIGhlaWdodD0iNTBweCIgdmlld0JveD0iMCAwIDI2IDUwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy4zLjIgKDEyMDQzKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5uZXh0PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+CiAgICAgICAgPGcgaWQ9Im5leHQiIHNrZXRjaDp0eXBlPSJNU0xheWVyR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuOTUxNDUxLCAwLjAwMDAwMCkiIGZpbGw9IiM1NjVBNUMiPgogICAgICAgICAgICA8cGF0aCBkPSJNMCw0OS4xMjM3MzMxIEwwLDQ1LjM2NzQzNDUgTDIwLjEzMTg0NTksMjQuNzIzMDYxMiBMMCw0LjIzMTM4MzE0IEwwLDAuNDc1MDg0NDU5IEwyNSwyNC43MjMwNjEyIEwwLDQ5LjEyMzczMzEgTDAsNDkuMTIzNzMzMSBaIiBpZD0icmlnaHQiIHNrZXRjaDp0eXBlPSJNU1NoYXBlR3JvdXAiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=");
    `}
`;

class App extends Component {
  state = {
    images: [
      "1-SBURNS.jpg",
      "2-SBURNS.jpg",
      "3-SBURNS.jpg",
      "4-SBURNS.jpg",
      "5-SBURNS.jpg",
      "6-SBURNS.jpg",
      "7-SBURNS.jpg",
    ],
    slideIndex: 0,
  };

  handleNext = () => {
    this.setState(prevState => {
      return { slideIndex: prevState.slideIndex + 1 };
    });
  };

  handlePrev = () => {
    this.setState(prevState => {
      return { slideIndex: prevState.slideIndex - 1 };
    });
  };

  render() {
    const { images } = this.state;

    return (
      <div className="App">
        <Container>
          <Header>
            <Name>Sandra Clark Burns</Name>
            <Contact>
              <a href="mailto:sandra.burns@yale.edu">Contact</a>
            </Contact>
          </Header>
          <ImagesContainer>
            <Carousel
              wrapAround={true}
              speed={1000}
              easing="easeQuadInOut"
              heightMode="max"
              cellSpacing={600}
              slideIndex={this.state.slideIndex}
              afterSlide={slideIndex => this.setState({ slideIndex })}
              withoutControls={true}
            >
              {images.map((image, index) => (
                <ImageContainer key={index}>
                  <img src={`/images/${image}`} alt="Sandra Clark Burns" />
                </ImageContainer>
              ))}
            </Carousel>
          </ImagesContainer>
          <Controls>
            <Arrow onClick={this.handlePrev}>Prev</Arrow>
            <Arrow nextArrow={true} onClick={this.handleNext}>
              Next
            </Arrow>
          </Controls>
        </Container>
      </div>
    );
  }
}

export default App;
